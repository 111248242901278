import React, { useState, useContext } from "react"
import locales from "../../config/locales"
import { LocaleContext } from "../LocaleContext"

function determineDefaultLocale() {
  for (const language in locales) {
    if (locales[language].default) {
      return locales[language]
    }
  }
}

function LocaleProvider({ children }) {
  const defaultLocale = determineDefaultLocale()

  const [locale, setLocale] = useState(defaultLocale)

  function changeLocale(language) {
    setLocale(locales[language])
  }

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}

function useLocale() {
  const locale = useContext(LocaleContext)
  if (!locale) {
    return determineDefaultLocale()
  }
  return locale
}

export { LocaleProvider, useLocale }
