exports.components = {
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-nl-js": () => import("./../../../src/pages/about.nl.js" /* webpackChunkName: "component---src-pages-about-nl-js" */),
  "component---src-pages-blogs-en-js": () => import("./../../../src/pages/blogs.en.js" /* webpackChunkName: "component---src-pages-blogs-en-js" */),
  "component---src-pages-blogs-nl-js": () => import("./../../../src/pages/blogs.nl.js" /* webpackChunkName: "component---src-pages-blogs-nl-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-nl-js": () => import("./../../../src/pages/contact.nl.js" /* webpackChunkName: "component---src-pages-contact-nl-js" */),
  "component---src-pages-home-en-js": () => import("./../../../src/pages/home.en.js" /* webpackChunkName: "component---src-pages-home-en-js" */),
  "component---src-pages-home-nl-js": () => import("./../../../src/pages/home.nl.js" /* webpackChunkName: "component---src-pages-home-nl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-en-js": () => import("./../../../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-projects-nl-js": () => import("./../../../src/pages/projects.nl.js" /* webpackChunkName: "component---src-pages-projects-nl-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

